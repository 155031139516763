import { render, staticRenderFns } from "./RadioEdit.vue?vue&type=template&id=13c4428a&scoped=true&"
import script from "./RadioEdit.vue?vue&type=script&lang=js&"
export * from "./RadioEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c4428a",
  null
  
)

export default component.exports